
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'

import { useAuthActions, useAuthGetters } from '@/store/modules/auth/useAuthModule'

import { mdiEye, mdiEyeOff, mdiEmail, mdiFormTextboxPassword } from '@mdi/js'
import { ValidationForm, required, email as emailRule } from '@/utils/validation'
import { useGetAuthMethods } from '@/api/auth'

export default defineComponent({
  name: 'login-view',
  beforeRouteEnter: (from, to, next) => {
    const { isAuthenticated } = useAuthGetters()
    !isAuthenticated.value ? next() : next({ name: 'home' })
  },
  setup(_, { root }) {
    const { login: authModuleLogin, ssoLogin } = useAuthActions(root.$store)
    const isLoading = ref(false)
    const baseURL = process.env.VUE_APP_API_URL || window.location.origin
    const { exec: getAuthMethods, data: authMethods } = useGetAuthMethods()
    getAuthMethods()
    const email = ref('')
    const password = ref('')

    const showPassword = ref(false)

    const loginForm = ref<null | ValidationForm>(null)
    const wrongCredentials = ref(false)
    const loginError = () => !wrongCredentials.value || (root.$t('login.error') as string)

    onMounted(() => {
      if (root.$route.query.token) {
        ssoLogin(root.$route.query.token as string)
        root.$router.push({ name: 'timetracking' }).catch(() => undefined)
      }
    })
    const login = () => {
      wrongCredentials.value = false
      if (loginForm.value?.validate()) {
        isLoading.value = true
        authModuleLogin({
          username: email.value,
          password: password.value,
        })
          .then(() => {
            root.$router.push({ name: 'timetracking' })
          })
          .catch(() => {
            wrongCredentials.value = true
            loginForm.value?.validate()
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    }

    return {
      icons: { mdiEye, mdiEyeOff, mdiEmail, mdiFormTextboxPassword },
      required,
      email,
      emailRule,
      loginError,
      password,
      showPassword,
      login,
      baseURL,
      isLoading,
      loginForm,
      authMethods,
    }
  },
})
